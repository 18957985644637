import '../../../assets/css/update.css';
import React from 'react';
import Header from "components/Headers/Header";
import Footer from "components/Footers/Footer";


import updatesData from '../../../update.json';
// import updatesData from '../../../update-prd.json';

function Update() {
  return (
    <div className="Update">
      <Header />
      <div className="container">
        <div style={{ padding: "2rem" }}>
          {updatesData.map((update, updateIndex) => (
            <div className="col-sm-12 col-lg-12 col-xl-12 mb-4" key={updateIndex}>
              <div className="text-left">
                <p className="version">
                  <span className="highlight">Version {update.version}</span> (Update on {update.updated_on})
                </p>
                <ul className="update-list">
                  {update.detail.map((item, index) => (
                    <li key={index}>{item.startsWith('-') ? item : `- ${item}`}</li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer isPadding={false} />
    </div>
  );
}

export default Update;
