import React, { useEffect } from 'react';
import Axios from "axios";

// Utils
import { createAlert } from 'utils/MainUtils';

function FormImg({ userId, userImgList, setUserImgList }) {
    useEffect(() => {
        const initialize = async () => {
            try {
                // เรียก API เพื่อข้อมูลรูปภาพทั้งหมด
                const img1 = await Axios.post(`${process.env.REACT_APP_API_URL}/api/get-user-img`, {
                    user_id: userId,
                    img_seq: 1
                }, {
                    headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
                });

                const img2 = await Axios.post(`${process.env.REACT_APP_API_URL}/api/get-user-img`, {
                    user_id: userId,
                    img_seq: 2
                }, {
                    headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
                });

                const img3 = await Axios.post(`${process.env.REACT_APP_API_URL}/api/get-user-img`, {
                    user_id: userId,
                    img_seq: 3
                }, {
                    headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
                });

                // อัพเดต userImgList โดยเพิ่มรูปภาพใหม่โดยไม่ทับแทนที่ข้อมูลเดิม
                const imgList = [img1.data, img2.data, img3.data];
                setUserImgList(imgList);
            } catch (error) {
                createAlert("FAILED", error);
            }
        };

        initialize();
    }, [userId]);

    const handleDeleteImage = (index) => {
        setUserImgList(prevList => {
            const newList = [...prevList];
            newList[index] = { status: "FAILED" }; // ตั้งค่า status เป็น "FAILED" เพื่อแสดงว่ารูปถูกลบชั่วคราว
            return newList;
        });
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            // อ่านไฟล์และแปลงเป็น URL ของไฟล์
            const reader = new FileReader();
            reader.onloadend = () => {
                const imageUrl = reader.result;
                // เพิ่มรูปภาพใหม่เข้าไปใน userImgList แทนที่รูปที่ถูกลบชั่วคราว
                setUserImgList(prevList => {
                    const newList = [...prevList];
                    const index = newList.findIndex(img => img.status === "FAILED");
                    if (index !== -1) {
                        newList[index] = { status: "NEW", message: 'Upload New Image', image: imageUrl };
                    }
                    return newList;
                });
            };
            reader.readAsDataURL(file);
        }
    };

    const renderSmallImage = (img, index) => {
        if (img.status === "FAILED") {
            return (
                <div key={index} className="small-image-card">
                    <div className="small-image">
                        <label htmlFor={`file-input-${index}`}>
                            <i className="fa fa-plus-circle fa-5x" style={{ color: 'lightgray', cursor: 'pointer' }}></i>
                            <input id={`file-input-${index}`} type="file" style={{ display: 'none' }} onChange={handleFileChange} />
                        </label>
                    </div>
                </div>
            );
        } else {
            return (
                <div key={index} className="small-image-card">
                    <div className="small-image" style={{ position: 'relative' }}>
                        <img
                            src={img.image}
                            alt={`Small ${index + 1}`}
                            className="small-image"
                        />
                        {img.status === "OK" && (
                            <a onClick={() => handleDeleteImage(index)} style={{ cursor: 'pointer', textDecoration: 'underline', color: 'black', position: 'absolute', bottom: '-25px', left: '50%', transform: 'translateX(-50%)', zIndex: '1', background: 'white', padding: '5px 10px', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0,0,0,0.2)' }}>
                                Remove Photo
                            </a>
                        )}
                        {img.status === "NEW" && (
                            <a onClick={() => handleDeleteImage(index)} style={{ cursor: 'pointer', textDecoration: 'underline', color: 'black', position: 'absolute', bottom: '-25px', left: '50%', transform: 'translateX(-50%)', zIndex: '1', background: 'white', padding: '5px 10px', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0,0,0,0.2)' }}>
                                Remove Photo
                            </a>
                        )}
                    </div>
                </div>
            );
        }
    };

    return (
        <div id="section-1" style={{ padding: "1rem" }}>
            <div className="row">
                <div className="col-12 col-sm-12 col-lg-12">
                    <div className="row">
                        <div className="col-12">
                            <div className="small-images-container">
                                {/* แสดงรูปภาพที่ได้จาก userImgList ในลำดับ 1, 2, และ 3 */}
                                {userImgList.slice(0, 3).map((img, index) => renderSmallImage(img, index))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FormImg;