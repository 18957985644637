import '../../../assets/css/search.css';
import Header from "components/Headers/Header";
import Footer from "components/Footers/Footer";
import UserCard from "components/User/UserCard";
import React, { useState, useEffect } from 'react';
import Axios from 'axios';

// Utils
import { getDataByUserId, getUserSys, createAlert } from 'utils/MainUtils';

function Search() {
  const [loading, setLoading] = useState(true);
  const [userList, setUserList] = useState([]);
  const [filter, setFilter] = useState('ALL'); // State for filter

  useEffect(() => {
    const initialize = async () => {
      try {
        const userId = localStorage.getItem('user_id');

        if (!userId) {
          createAlert("FAILED", "User ID not found.");
          return;
        }

        // รอให้ Promises เสร็จสมบูรณ์ก่อน
        const userSys = await getUserSys('GENDER_FOR', userId);
        const dateInfo = await getDataByUserId('DATE_INFO', userId);

        if (!userSys || userSys.length === 0 || !userSys[0].ref_code) {
          createAlert("FAILED", "Gender looking for not found.");
          return;
        }

        if (!dateInfo || dateInfo.length === 0 || !dateInfo[0].age_start || !dateInfo[0].age_end) {
          createAlert("FAILED", "Age range of interest not found.");
          return;
        }

        // Update API call to include gender filter
        const response = await Axios.post(`${process.env.REACT_APP_API_URL}/api/get-category`, {
          user_id: userId,
          gender_for: filter === 'ALL' ? undefined : filter,
          age_start: dateInfo[0].age_start,
          age_end: dateInfo[0].age_end
        }, {
          headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
        });

        const usersWithImages = await Promise.all(response.data.map(async (user) => {
          const imgResponse = await Axios.post(`${process.env.REACT_APP_API_URL}/api/get-user-img`, {
            user_id: user.id,
            img_seq: 1
          }, {
            headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
          });

          return {
            ...user,
            img: imgResponse.data.image
          };
        }));

        setUserList(usersWithImages);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        createAlert("FAILED", error.message);
      }
    };

    initialize();
  }, [filter]); // Dependency array includes filter

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  return (
    <div className="Search">
      <Header />
      <div className="container mt-4">
        <div className="search-filter d-flex justify-content-center mb-4">
          <div>
            <label className="form-check-label me-2">Filter by:</label>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="genderFilter"
                value="ALL"
                checked={filter === 'ALL'}
                onChange={handleFilterChange}
              />
              <label className="form-check-label">All</label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="genderFilter"
                value="M"
                checked={filter === 'M'}
                onChange={handleFilterChange}
              />
              <label className="form-check-label">Man</label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="genderFilter"
                value="F"
                checked={filter === 'F'}
                onChange={handleFilterChange}
              />
              <label className="form-check-label">Woman</label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="genderFilter"
                value="LB"
                checked={filter === 'LB'}
                onChange={handleFilterChange}
              />
              <label className="form-check-label">Ladyboy</label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="genderFilter"
                value="TG"
                checked={filter === 'TG'}
                onChange={handleFilterChange}
              />
              <label className="form-check-label">Transgender</label>
            </div>
          </div>
        </div>
      </div>

      {loading ? (
        <div className="preloader-wrapper"><div className="preloader"></div></div>
      ) : (
        <UserCard userList={userList} message={""} style={{}} />
      )}

      <Footer isPadding={false} />
    </div>
  );
}

export default Search;