import '../../../assets/css/register.css';

import React, { useEffect, useState } from 'react';
import Axios from "axios";
import Header from "components/Headers/Header";
import Footer from "components/Footers/Footer";

import FormAction from 'components/FormRegister/FormAction';
import FormRegister from 'components/FormRegister/FormRegister';
import FormSection1 from 'components/FormRegister/FormSection1';
import FormSection2 from 'components/FormRegister/FormSection2';
import FormSection3 from 'components/FormRegister/FormSection3';
import FormSection4 from 'components/FormRegister/FormSection4';
import Steps from 'components/Headers/Steps';

// Utils
import {
  getDataByUserId,
  getUserSys,
  setMaxDate,
  insertUserSysRef,
  deleteByUserId,
  createAlert,
  base64ToBlob,
  getFileTypeFromBase64
} from 'utils/MainUtils';

// Model
import useAbstractModelForm from 'model/AbstractModelForm';

function Register({ mode }) {
  const [loading, setLoading] = useState(true);
  const [sectionIndex, setSectionIndex] = useState(0);
  const [sections, setSections] = useState([false, true, true, true, true]);
  const [isBackLoading, setIsBackLoading] = useState(false);
  const [isNextLoading, setIsNextLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  // AbstractModelForm
  const {
    userId,
    setUserId,
    activeFlag,
    setActiveFlag,
    createUser,
    setCreateUser,
    createDatetime,
    setCreateDatetime,
    createProcess,
    setCreateProcess,
    updateUser,
    setUpdateUser,
    updateDatetime,
    setUpdateDatetime,
    updateProcess,
    setUpdateProcess
  } = useAbstractModelForm();

  // Section 1
  const [name, setName] = useState('');
  const [gender, setGender] = useState('');
  const [occupation, setOccupation] = useState('');
  const [birthdate, setBirthdate] = useState('');
  const [country, setCountry] = useState('');
  const [caption, setCaption] = useState('');
  const [cEmail, setCEmail] = useState('');
  const [line, setLine] = useState('');
  const [whatsApp, setWhatsApp] = useState('');
  const [facebook, setFacebook] = useState('');
  const [phone, setPhone] = useState('');
  const [userImgList, setUserImgList] = useState([]);

  // Section 2
  const [height, setHeight] = useState('');
  const [weight, setWeight] = useState('');
  const [shape, setShape] = useState('');
  const [maritalStatus, setMaritalStatus] = useState('');
  const [wantChild, setWantChild] = useState('');
  const [numChild, setNumChild] = useState('');
  const [liveWith, setLiveWith] = useState('');
  const [influencePerson, setInfluencePerson] = useState('');
  const [ethnicity, setEthnicity] = useState('');
  const [religion, setReligion] = useState('');
  const [zodiacSign, setZodiacSign] = useState('');
  const [education, setEducation] = useState('');
  const [valuePerson, setValuePerson] = useState([]);

  // Section 3
  const [drinking, setDrinking] = useState('');
  const [favorite, setFavorite] = useState([]);
  const [sexualOrien, setSexualOrien] = useState('');
  const [freeTime, setFreeTime] = useState('');
  const [politics, setPolitics] = useState('');
  const [smoking, setSmoking] = useState('');
  const [favoriteFood, setFavoriteFood] = useState([]);
  const [favoriteSport, setFavoriteSport] = useState([]);
  const [community, setCommunity] = useState([]);

  // Section 4
  const [lookingFor, setLookingFor] = useState('');
  const [genderFor, setGenderFor] = useState('');
  const [ageStart, setAgeStart] = useState('18');
  const [ageEnd, setAgeEnd] = useState('18');
  const [usedCommu, setUsedCommu] = useState('');
  const [learningThai, setLearningThai] = useState('');
  const [thSpouseReason, setThSpouseReason] = useState('');
  const [thWomenThink, setThWomenThink] = useState('');
  const [spouseExpect, setSpouseExpect] = useState('');

  useEffect(() => {
    const initialize = async () => {
      try {
        const token = localStorage.getItem('token');
        let user_id = localStorage.getItem('user_id');

        setActiveFlag('Y');

        const currentUser = 'User';
        const currentDate = new Date();
        const currentProcess = window.location.pathname;

        if (mode === "INSERT") {
          setCreateUser(currentUser);
          setCreateDatetime(currentDate);
          setCreateProcess(currentProcess);
          await setMaxDate();
        }

        setUpdateUser(currentUser);
        setUpdateDatetime(currentDate);
        setUpdateProcess(currentProcess);

        if (mode === "UPDATE" || mode === "EDIT") {
          if (token) {
            const authen = await Axios.post(`${process.env.REACT_APP_API_URL}/api/authen`, { name: '' }, {
              headers: { 'Authorization': 'Bearer ' + token }
            });

            if (authen.data.status === 'OK') {
              const res = await Axios.get(`${process.env.REACT_APP_API_URL}/api/get-user-by-username/${authen.data.decoded.username}`, {
                headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
              });

              if (res.data.status === 'FAILED') {
                window.location.replace("/login");
              } else {
                user_id = res.data.user[0].user_id;
                localStorage.setItem('user_id', user_id);
                setUserId(user_id);
                if (mode === "UPDATE") {
                  changeSteps(1);
                }
                await getModelById(user_id);
              }
            }
          }
        } else {
          if (!user_id) {
            localStorage.removeItem('user_id');
          }
        }

        if (mode === 'EDIT') {
          const sectionIndex = parseInt(localStorage.getItem('section_index'), 10);
          setSectionIndex(sectionIndex);
          setSections(prevSections => prevSections.map((_, i) => i !== sectionIndex));
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
        //createAlert("FAILED", error.message);
      }
    };

    initialize();
  }, [mode]);


  const getModelById = async (userId) => {
    try {
      // USER_INFO
      const userInfo = await getDataByUserId("USER_INFO", userId);
      if (userInfo && userInfo.length > 0) {
        setName(userInfo[0].name);
        setOccupation(userInfo[0].occupation);
        setBirthdate(userInfo[0].birthdate);
        setCountry(userInfo[0].country);
        setCaption(userInfo[0].caption);
        setCEmail(userInfo[0].email);
        setLine(userInfo[0].line);
        setWhatsApp(userInfo[0].whats_app);
        setFacebook(userInfo[0].facebook);
        setPhone(userInfo[0].phone);
        const genderRf = await getUserSys("GENDER", userId);
        setGender(genderRf && genderRf.length > 0 ? genderRf[0].ref_code : '');
        setCreateUser(userInfo[0].create_user);
        setCreateDatetime(userInfo[0].create_datetime);
        setCreateProcess(userInfo[0].create_process);
      }

      // PERSON_INFO
      const personInfo = await getDataByUserId("PERSON_INFO", userId);
      if (personInfo && personInfo.length > 0) {
        setHeight(personInfo[0].height);
        setWeight(personInfo[0].weight);
        setNumChild(personInfo[0].num_child);
        setCreateUser(personInfo[0].create_user);
        setCreateDatetime(personInfo[0].create_datetime);
        setCreateProcess(personInfo[0].create_process);

        const shapeRf = await getUserSys("SHAPE", userId);
        setShape(shapeRf && shapeRf.length > 0 ? shapeRf[0].ref_code : '');

        const maritalStatusRf = await getUserSys("MARITAL_STAT", userId);
        setMaritalStatus(maritalStatusRf && maritalStatusRf.length > 0 ? maritalStatusRf[0].ref_code : '');

        const wantChildRf = await getUserSys("KIDS", userId);
        setWantChild(wantChildRf && wantChildRf.length > 0 ? wantChildRf[0].ref_code : '');

        const liveWithRf = await getUserSys("LIVE_WITH", userId);
        setLiveWith(liveWithRf && liveWithRf.length > 0 ? liveWithRf[0].ref_code : '');

        const influencePersonRf = await getUserSys("INFLUENCES_FACTOR", userId);
        setInfluencePerson(influencePersonRf && influencePersonRf.length > 0 ? influencePersonRf[0].ref_code : '');

        const ethnicityRf = await getUserSys("ETHNICITY", userId);
        setEthnicity(ethnicityRf && ethnicityRf.length > 0 ? ethnicityRf[0].ref_code : '');

        const religionRf = await getUserSys("RELIGION", userId);
        setReligion(religionRf && religionRf.length > 0 ? religionRf[0].ref_code : '');

        const zodiacSignRf = await getUserSys("ZODIAC", userId);
        setZodiacSign(zodiacSignRf && zodiacSignRf.length > 0 ? zodiacSignRf[0].ref_code : '');

        const educationRf = await getUserSys("EDUCATION", userId);
        setEducation(educationRf && educationRf.length > 0 ? educationRf[0].ref_code : '');

        const valuePersonRf = await getUserSys("PERSON_VALUE", userId);
        setValuePerson(valuePersonRf && valuePersonRf.length > 0 ? valuePersonRf.map(vp => vp.ref_code) : []);
      }

      // FAVORITE_INFO
      const favoriteInfo = await getDataByUserId("FAVORITE_INFO", userId);
      if (favoriteInfo && favoriteInfo.length > 0) {
        setFreeTime(favoriteInfo[0].free_time);
        setCreateUser(favoriteInfo[0].create_user);
        setCreateDatetime(favoriteInfo[0].create_datetime);
        setCreateProcess(favoriteInfo[0].create_process);

        const drinkingRf = await getUserSys("DRINKING", userId);
        setDrinking(drinkingRf && drinkingRf.length > 0 ? drinkingRf[0].ref_code : '');

        const favoriteRf = await getUserSys("FAVORITE", userId);
        setFavorite(favoriteRf && favoriteRf.length > 0 ? favoriteRf.map(vp => vp.ref_code) : []);

        const sexualOrienRf = await getUserSys("SEXUAL_ORIEN", userId);
        setSexualOrien(sexualOrienRf && sexualOrienRf.length > 0 ? sexualOrienRf[0].ref_code : '');

        const politicsRf = await getUserSys("POLITICS", userId);
        setPolitics(politicsRf && politicsRf.length > 0 ? politicsRf[0].ref_code : '');

        const smokingRf = await getUserSys("SMOKING", userId);
        setSmoking(smokingRf && smokingRf.length > 0 ? smokingRf[0].ref_code : '');

        const favoriteFoodRf = await getUserSys("FOOD", userId);
        setFavoriteFood(favoriteFoodRf && favoriteFoodRf.length > 0 ? favoriteFoodRf.map(vp => vp.ref_code) : []);

        const favoriteSportRf = await getUserSys("SPORT", userId);
        setFavoriteSport(favoriteSportRf && favoriteSportRf.length > 0 ? favoriteSportRf.map(vp => vp.ref_code) : []);

        const communityRf = await getUserSys("COMMUNITY", userId);
        setCommunity(communityRf && communityRf.length > 0 ? communityRf.map(vp => vp.ref_code) : []);
      }

      // DATE_INFO
      const dateInfo = await getDataByUserId("DATE_INFO", userId);
      if (dateInfo && dateInfo.length > 0) {
        setAgeStart(dateInfo[0].age_start);
        setAgeEnd(dateInfo[0].age_end);
        setThSpouseReason(dateInfo[0].th_spouse_reason);
        setThWomenThink(dateInfo[0].th_women_think);
        setSpouseExpect(dateInfo[0].spouse_expect);
        setCreateUser(dateInfo[0].create_user);
        setCreateDatetime(dateInfo[0].create_datetime);
        setCreateProcess(dateInfo[0].create_process);

        const lookingForRf = await getUserSys("LOOKING_FOR", userId);
        setLookingFor(lookingForRf && lookingForRf.length > 0 ? lookingForRf[0].ref_code : '');

        const genderForRf = await getUserSys("GENDER_FOR", userId);
        setGenderFor(genderForRf && genderForRf.length > 0 ? genderForRf[0].ref_code : '');

        const langImportantRf = await getUserSys("LANG_IMPORTANT", userId);
        setUsedCommu(langImportantRf && langImportantRf.length > 0 ? langImportantRf[0].ref_code : '');

        const learnThRf = await getUserSys("LEARN_TH", userId);
        setLearningThai(learnThRf && learnThRf.length > 0 ? learnThRf[0].ref_code : '');
      }
    } catch (error) {
      createAlert("FAILED", "Error fetching user data: " + error);
    }
  };

  const changeSteps = async (direction) => {
    const newIndex = sectionIndex + direction;
    if (newIndex < 0 || newIndex >= sections.length) return;

    setSectionIndex(newIndex);
    setSections(sections.map((_, i) => i !== newIndex));
  };

  const handleConfirmClick = async () => {
    if (document.getElementById('email').value === '') {
      createAlert("FAILED", "Please fill in Email");
    } else if (document.getElementById('username').value === '') {
      createAlert("FAILED", "Please fill in Username");
    } else if (document.getElementById('password').value === '') {
      createAlert("FAILED", "Please fill in Password");
    } else if (document.getElementById('c_password').value === '') {
      createAlert("FAILED", "Please fill in Confirm Password");
    } else {
      if (document.getElementById('password').value !== document.getElementById('c_password').value) {
        createAlert("FAILED", "Password and Confirm Password do not match.");
      } else {
        document.getElementById('confirm-dialog-btn').click();
      }
    }
  };

  const insertUserLogin = async (status) => {
    try {
      const userData = {
        email: document.getElementById('email').value,
        username: document.getElementById('username').value,
        password: document.getElementById('password').value,
        role: "U",
        active_flag: activeFlag,
        create_user: createUser,
        create_process: createProcess
      };

      const response = await Axios.post(`${process.env.REACT_APP_API_URL}/api/create-user-login`, userData, {
        headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
      });

      if (response.data.status !== 'OK') {
        return createAlert(response.data.status, response.data.message);
      }

      const userId = response.data.user_id;
      await insertUserImg(userId, document.getElementById('img-file').files[0], "1");

      if (status === "proceed") {
        await handleLogin(userId);
      } else if (status === "later") {
        window.location.replace("/login");
      }
    } catch (error) {
      createAlert("FAILED", error.message);
    }
  };

  const insertUserImg = async (userId, imgFile, imgSeq) => {
    try {
      if (!imgFile) return;

      const formData = new FormData();
      formData.append('image', imgFile);

      const uploadResponse = await Axios.post(`${process.env.REACT_APP_API_URL}/api/upload`, formData, {
        headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
      });

      if (uploadResponse.data.status !== 'OK') {
        return createAlert(uploadResponse.data.status, uploadResponse.data.message);
      }

      const imgData = {
        user_id: userId,
        img_type: uploadResponse.data.contentType,
        img_file: uploadResponse.data.fileId,
        img_seq: imgSeq,
        img_size: uploadResponse.data.fileSize,
        active_flag: activeFlag,
        create_user: createUser,
        create_process: createProcess
      };

      const userImgResponse = await Axios.post(`${process.env.REACT_APP_API_URL}/api/create-user-img`, imgData, {
        headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
      });

      if (userImgResponse.data.status === 'FAILED') {
        return createAlert(userImgResponse.data.status, userImgResponse.data.message);
      }
    } catch (error) {
      createAlert("FAILED", error.message);
    }
  };

  const handleLogin = async (userId) => {
    try {
      const loginData = {
        username: document.getElementById('username').value,
        password: document.getElementById('password').value
      };

      const responseLogin = await Axios.post(`${process.env.REACT_APP_API_URL}/api/user/login`, loginData, {
        headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
      });

      if (responseLogin.data.status === 'OK') {
        localStorage.setItem('token', responseLogin.data.token);
        localStorage.setItem('user_id', userId);
        window.location.replace("/register");
      } else {
        localStorage.removeItem('token');
        localStorage.removeItem('user_id');
        createAlert(responseLogin.data.status, responseLogin.data.message);
      }
    } catch (error) {
      createAlert("FAILED", error.message);
    }
  };

  const insertUserInfo = async () => {
    try {
      const fields = [
        { id: "v-userId", value: userId, message: "Not Found User" },
        { id: "ip-name", value: name, message: "Please fill in your name" },
        { id: "at-gender", value: gender, message: "Please select your gender" },
        { id: "ip-birth", value: birthdate, message: "Please fill in your birthdate" },
        { id: "ip-country", value: country, message: "Please fill in your country" }
      ];

      for (const field of fields) {
        if (!field.value) {
          createAlert("FAILED", field.message);
          if (field.id) {
            document.getElementById(field.id).style.color = "red";
            document.getElementById(field.id).style.fontWeight = "bold";
          }
          return;
        }
      }

      // ลบข้อมูลผู้ใช้เดิม
      await deleteByUserId("USER_INFO", null, userId);

      // เพิ่มข้อมูลผู้ใช้ใหม่
      const response = await Axios.post(`${process.env.REACT_APP_API_URL}/api/create-user-info`, {
        user_id: userId,
        name: name,
        occupation: occupation,
        birthdate: birthdate,
        country: country,
        caption: caption,
        c_email: cEmail,
        line: line,
        whats_app: whatsApp,
        facebook: facebook,
        phone: phone,
        active_flag: activeFlag,
        create_user: createUser,
        create_datetime: createDatetime,
        create_process: createProcess,
        update_user: updateUser,
        update_datetime: updateDatetime,
        update_process: updateProcess
      }, {
        headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
      });

      if (response.data.status === 'OK') {
        // ลบและเพิ่มข้อมูลอ้างอิงเพศใหม่
        await insertUserSysRef(userId, "GENDER", gender, null, activeFlag, createUser, createProcess);

        if (mode === 'EDIT') {
          // แสดง confirm dialog และรับค่าที่ผู้ใช้เลือก
          var confirmed = confirm("Are you sure you want to confirm the data?");

          if (confirmed) {
            await handleUploadNewImages();
            window.location.replace("/my-profile");
          } else {
            // กรณีผู้ใช้เลือกยกเลิก
            // ทำอะไรต่อก็ตามที่ต้องการ เช่นไม่ redirect ไปยังหน้า /my-profile
          }
        } else {
          changeSteps(1);
        }
      } else {
        createAlert(response.data.status, response.data.message);
      }
    } catch (error) {
      createAlert("FAILED", error.message);
    }
  };

  const insertPersonalInfo = async () => {
    try {
      const fields = [
        { id: "v-userId", value: userId, message: "Not Found User" },
        { id: "ip-height", value: height, message: "Please fill in Height" },
        { id: "ip-weight", value: weight, message: "Please fill in Weight" },
        { id: "at-marital", value: maritalStatus, message: "Please select Marital Status" },
        { id: "at-kids", value: wantChild, message: "Please select if you want children" },
        { id: "at-ethnicity", value: ethnicity, message: "Please select Ethnicity" },
        { id: "at-religion", value: religion, message: "Please select Religion" },
        { id: "at-education", value: education, message: "Please select Education" },
      ];

      for (const field of fields) {
        if (!field.value) {
          createAlert("FAILED", field.message);
          if (field.id) {
            document.getElementById(field.id).style.color = "red";
            document.getElementById(field.id).style.fontWeight = "bold";
          }
          return;
        }
      }

      // ลบข้อมูลผู้ใช้เดิม
      await deleteByUserId("PERSON_INFO", null, userId);

      // เพิ่มข้อมูลผู้ใช้ใหม่
      const response = await Axios.post(`${process.env.REACT_APP_API_URL}/api/create-person-info`, {
        user_id: userId,
        height: height,
        weight: weight,
        num_child: numChild,
        active_flag: activeFlag,
        create_user: createUser,
        create_datetime: createDatetime,
        create_process: createProcess,
        update_user: updateUser,
        update_datetime: updateDatetime,
        update_process: updateProcess
      }, {
        headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
      });

      if (response.data.status === 'OK') {
        // เพิ่มข้อมูลอ้างอิงผู้ใช้
        await insertUserSysRef(userId, "SHAPE", shape, null, activeFlag, createUser, createProcess);
        await insertUserSysRef(userId, "MARITAL_STAT", maritalStatus, null, activeFlag, createUser, createProcess);
        await insertUserSysRef(userId, "KIDS", wantChild, null, activeFlag, createUser, createProcess);
        await insertUserSysRef(userId, "LIVE_WITH", liveWith, null, activeFlag, createUser, createProcess);
        await insertUserSysRef(userId, "INFLUENCES_FACTOR", influencePerson, null, activeFlag, createUser, createProcess);
        await insertUserSysRef(userId, "ETHNICITY", ethnicity, null, activeFlag, createUser, createProcess);
        await insertUserSysRef(userId, "RELIGION", religion, null, activeFlag, createUser, createProcess);
        await insertUserSysRef(userId, "ZODIAC", zodiacSign, null, activeFlag, createUser, createProcess);
        await insertUserSysRef(userId, "EDUCATION", education, null, activeFlag, createUser, createProcess);

        await Promise.all(valuePerson.map(async (value) => {
          await insertUserSysRef(userId, "PERSON_VALUE", value, null, activeFlag, createUser, createProcess);
        }));

        if (mode === 'EDIT') {
          // แสดง confirm dialog และรับค่าที่ผู้ใช้เลือก
          var confirmed = confirm("Are you sure you want to confirm the data?");

          if (confirmed) {
            window.location.replace("/my-profile");
          } else {
            // กรณีผู้ใช้เลือกยกเลิก
            // ทำอะไรต่อก็ตามที่ต้องการ เช่นไม่ redirect ไปยังหน้า /my-profile
          }
        } else {
          changeSteps(1);
        }
      } else {
        createAlert(response.data.status, response.data.message);
      }
    } catch (error) {
      createAlert("FAILED", error.message);
    }
  };

  const insertFavoriteInfo = async () => {
    try {
      const fields = [
        { id: "v-userId", value: userId, message: "Not Found User" },
        { id: "at-drinking", value: drinking, message: "Please select Drinking" },
        { id: "at-smoking", value: smoking, message: "Please select Smoking" },
      ];

      for (const field of fields) {
        if (!field.value) {
          createAlert("FAILED", field.message);
          if (field.id) {
            document.getElementById(field.id).style.color = "red";
            document.getElementById(field.id).style.fontWeight = "bold";
          }
          return;
        }
      }

      // ลบข้อมูลผู้ใช้เดิม
      await deleteByUserId("FAVORITE_INFO", null, userId);

      // เพิ่มข้อมูลผู้ใช้ใหม่
      const response = await Axios.post(`${process.env.REACT_APP_API_URL}/api/create-favorite-info`, {
        user_id: userId,
        free_time: freeTime,
        active_flag: activeFlag,
        create_user: createUser,
        create_datetime: createDatetime,
        create_process: createProcess,
        update_user: updateUser,
        update_datetime: updateDatetime,
        update_process: updateProcess
      }, {
        headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
      });

      if (response.data.status === 'OK') {
        // เพิ่มข้อมูลอ้างอิงผู้ใช้
        await insertUserSysRef(userId, "DRINKING", drinking, null, activeFlag, createUser, createProcess);
        await insertUserSysRef(userId, "SEXUAL_ORIEN", sexualOrien, null, activeFlag, createUser, createProcess);
        await insertUserSysRef(userId, "POLITICS", politics, null, activeFlag, createUser, createProcess);
        await insertUserSysRef(userId, "SMOKING", smoking, null, activeFlag, createUser, createProcess);

        await Promise.all(favorite.map(async (value) => {
          await insertUserSysRef(userId, "FAVORITE", value, null, activeFlag, createUser, createProcess);
        }));

        await Promise.all(favoriteFood.map(async (value) => {
          await insertUserSysRef(userId, "FOOD", value, null, activeFlag, createUser, createProcess);
        }));

        await Promise.all(favoriteSport.map(async (value) => {
          await insertUserSysRef(userId, "SPORT", value, null, activeFlag, createUser, createProcess);
        }));

        await Promise.all(community.map(async (value) => {
          await insertUserSysRef(userId, "COMMUNITY", value, null, activeFlag, createUser, createProcess);
        }));

        if (mode === 'EDIT') {
          // แสดง confirm dialog และรับค่าที่ผู้ใช้เลือก
          var confirmed = confirm("Are you sure you want to confirm the data?");

          if (confirmed) {
            window.location.replace("/my-profile");
          } else {
            // กรณีผู้ใช้เลือกยกเลิก
            // ทำอะไรต่อก็ตามที่ต้องการ เช่นไม่ redirect ไปยังหน้า /my-profile
          }
        } else {
          changeSteps(1);
        }
      } else {
        createAlert(response.data.status, response.data.message);
      }
    } catch (error) {
      createAlert("FAILED", error.message);
    }
  };

  const insertDateInfo = async () => {
    try {
      const fields = [
        { id: "v-userId", value: userId, message: "Not Found User" },
        { id: "at-lookingFor", value: lookingFor, message: "Please select Looking For" },
        { id: "at-genderFor", value: genderFor, message: "Please select Gender For" },
        { id: "at-usedCommu", value: usedCommu, message: "Please select Used Communication" },
        { id: "at-learningThai", value: learningThai, message: "Please fill Learning Thai Language" },
      ];

      // ต้องดักเฉพาะ User ต่างชาติ
      if (gender === 'M' && country !== 'Thailand') {
        fields.push(
          { id: "ta-thSpouseReason", value: thSpouseReason, message: "Please fill Thai Spouse Reason" },
          { id: "ta-thWomenThink", value: thWomenThink, message: "Please fill What do you think about Thai women/man?" }
        );
      }

      for (const field of fields) {
        if (!field.value) {
          createAlert("FAILED", field.message);
          if (field.id) {
            document.getElementById(field.id).style.color = "red";
            document.getElementById(field.id).style.fontWeight = "bold";
          }
          return;
        }
      }

      // ลบข้อมูลผู้ใช้เดิม
      await deleteByUserId("DATE_INFO", null, userId);

      // เพิ่มข้อมูลผู้ใช้ใหม่
      const response = await Axios.post(`${process.env.REACT_APP_API_URL}/api/create-date-info`, {
        user_id: userId,
        age_start: ageStart,
        age_end: ageEnd,
        th_spouse_reason: thSpouseReason,
        th_women_think: thWomenThink,
        spouse_expect: spouseExpect,
        active_flag: activeFlag,
        create_user: createUser,
        create_datetime: createDatetime,
        create_process: createProcess,
        update_user: updateUser,
        update_datetime: updateDatetime,
        update_process: updateProcess
      }, {
        headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
      });

      if (response.data.status === 'OK') {
        // เพิ่มข้อมูลอ้างอิงผู้ใช้
        await insertUserSysRef(userId, "LOOKING_FOR", lookingFor, null, activeFlag, createUser, createProcess);
        await insertUserSysRef(userId, "GENDER_FOR", genderFor, null, activeFlag, createUser, createProcess);
        await insertUserSysRef(userId, "LANG_IMPORTANT", usedCommu, null, activeFlag, createUser, createProcess);
        await insertUserSysRef(userId, "LEARN_TH", learningThai, null, activeFlag, createUser, createProcess);

        if (mode === 'EDIT') {
          // แสดง confirm dialog และรับค่าที่ผู้ใช้เลือก
          var confirmed = confirm("Are you sure you want to confirm the data?");

          if (confirmed) {
            window.location.replace("/my-profile");
          } else {
            // กรณีผู้ใช้เลือกยกเลิก
            // ทำอะไรต่อก็ตามที่ต้องการ เช่นไม่ redirect ไปยังหน้า /my-profile
          }
        } else {
          window.location.replace("/");
        }
      } else {
        createAlert(response.data.status, response.data.message);
      }
    } catch (error) {
      createAlert("FAILED", error.message);
    }
  };

  const handleBackClick = async () => {
    setIsBackLoading(true);
    setIsButtonDisabled(true); // ปิดการใช้งานปุ่มในขณะที่กำลังโหลด
    setTimeout(() => {
      setIsBackLoading(false);
      changeSteps(-1);
      setIsButtonDisabled(false); // เปิดการใช้งานปุ่มเมื่อโหลดเสร็จสิ้น
    }, 1000);
  };

  const handleNextClick = async (insertFunction) => {
    setIsNextLoading(true);
    setIsButtonDisabled(true); // ปิดการใช้งานปุ่มในขณะที่กำลังโหลด

    const submitAfterDelay = async () => {
      await insertFunction(); // เรียกใช้งานฟังก์ชัน
      setIsNextLoading(false);
      setIsButtonDisabled(false); // เปิดการใช้งานปุ่มเมื่อโหลดเสร็จสิ้น
    };

    setTimeout(submitAfterDelay, 500);
  };

  const handleUploadNewImages = async () => {
    try {
      for (let index = 0; index < userImgList.length; index++) {
        const img = userImgList[index];
        if (img.status === 'NEW') {
          const fileType = getFileTypeFromBase64(img.image);
          const blob = base64ToBlob(img.image, fileType); // แปลง Base64 เป็น Blob
          const file = new File([blob], `image_${index + 1}.${fileType.split('/')[1]}`, { type: fileType });

          const requestData = {
            user_id: userId,
            img_seq: index + 1
          };

          // ลบรูปภาพเดิม
          await Axios.post(`${process.env.REACT_APP_API_URL}/api/delete-user-img`, requestData, {
            headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
          });

          await insertUserImg(userId, file, (index + 1).toString());
        }
      }
    } catch (error) {
      createAlert("FAILED", error.message);
    }
  };

  (function () {
    'use strict'

    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    var forms = document.querySelectorAll('.needs-validation')

    // Loop over them and prevent submission
    Array.prototype.slice.call(forms)
      .forEach(function (form) {
        form.addEventListener('submit', function (event) {
          if (!form.checkValidity()) {
            event.preventDefault()
            event.stopPropagation()
          }

          form.classList.add('was-validated')
        }, false)
      })
  })()

  return (
    <div className="Register" style={{ height: "100vh" }}>
      <Header />
      {loading ? (
        <div className="preloader-wrapper"><div className="preloader"></div></div>
      ) : (
        <>
          <div className="content" style={{ backgroundColor: "#fae8de" }}>
            <br />
            <div className="container h-100">
              <div className="row h-100">
                <div className="col-sm-20 col-md-16 col-lg-12 mx-auto d-table h-100">
                  <div className="d-table-cell align-middle">
                    <div className="card">
                      <div className="card-body">
                        <div className="m-sm-4">
                          <Steps mode={mode} sectionIndex={sectionIndex} />

                          {/* Section 0 */}
                          {mode !== 'EDIT' && (
                            <form className="row g-3 needs-validation" noValidate={true} hidden={sections[0]}
                              onSubmit={async (e) => { e.preventDefault(); await handleConfirmClick(); }}>
                              <FormRegister mode={mode} insertUserLogin={insertUserLogin} />
                            </form>
                          )}

                          {/* Section 1 */}
                          <form className="row g-3 needs-validation" noValidate={true} hidden={sections[1]}
                            onSubmit={async (e) => { e.preventDefault(); await handleNextClick(insertUserInfo); }}>
                            <FormSection1
                              mode={mode}
                              gender={gender}
                              setGender={setGender}
                              name={name}
                              setName={setName}
                              occupation={occupation}
                              setOccupation={setOccupation}
                              birthdate={birthdate}
                              setBirthdate={setBirthdate}
                              country={country}
                              setCountry={setCountry}
                              caption={caption}
                              setCaption={setCaption}
                              cEmail={cEmail}
                              setCEmail={setCEmail}
                              line={line}
                              setLine={setLine}
                              whatsApp={whatsApp}
                              setWhatsApp={setWhatsApp}
                              facebook={facebook}
                              setFacebook={setFacebook}
                              phone={phone}
                              setPhone={setPhone}
                              userImgList={userImgList}
                              setUserImgList={setUserImgList}
                            />
                            {mode !== 'EDIT' ? (
                              <FormAction
                                sectionIndex={sectionIndex}
                                isBackLoading={isBackLoading}
                                isNextLoading={isNextLoading}
                                isButtonDisabled={isButtonDisabled}
                                handleBackClick={handleBackClick}
                                handleNextClick={handleNextClick}
                              />
                            ) : (
                              <center>
                                <div className="col-sm-12 col-md-12 col-lg-6">
                                  <div className="text-center mt-5">
                                    <button type="submit" className="btn btn-danger" style={{ width: '50%' }}>SAVE</button>
                                  </div>
                                </div>
                              </center>
                            )}
                          </form>

                          {/* Section 2 */}
                          <form className="row g-3 needs-validation" noValidate={true} hidden={sections[2]}
                            onSubmit={async (e) => { e.preventDefault(); await handleNextClick(insertPersonalInfo); }}>
                            <FormSection2
                              height={height} setHeight={setHeight}
                              weight={weight} setWeight={setWeight}
                              shape={shape} setShape={setShape}
                              maritalStatus={maritalStatus} setMaritalStatus={setMaritalStatus}
                              wantChild={wantChild} setWantChild={setWantChild}
                              numChild={numChild} setNumChild={setNumChild}
                              liveWith={liveWith} setLiveWith={setLiveWith}
                              influencePerson={influencePerson} setInfluencePerson={setInfluencePerson}
                              ethnicity={ethnicity} setEthnicity={setEthnicity}
                              religion={religion} setReligion={setReligion}
                              zodiacSign={zodiacSign} setZodiacSign={setZodiacSign}
                              education={education} setEducation={setEducation}
                              valuePerson={valuePerson} setValuePerson={setValuePerson}
                            />
                            {mode !== 'EDIT' ? (
                              <FormAction
                                sectionIndex={sectionIndex}
                                isBackLoading={isBackLoading}
                                isNextLoading={isNextLoading}
                                isButtonDisabled={isButtonDisabled}
                                handleBackClick={handleBackClick}
                                handleNextClick={handleNextClick}
                              />
                            ) : (
                              <center>
                                <div className="col-sm-12 col-md-12 col-lg-6">
                                  <div className="text-center mt-5">
                                    <button type="submit" className="btn btn-danger" style={{ width: '50%' }}>SAVE</button>
                                  </div>
                                </div>
                              </center>
                            )}
                          </form>

                          {/* Section 3 */}
                          <form className="row g-3 needs-validation" noValidate={true} hidden={sections[3]}
                            onSubmit={async (e) => { e.preventDefault(); await handleNextClick(insertFavoriteInfo); }}>
                            <FormSection3
                              drinking={drinking} setDrinking={setDrinking}
                              favorite={favorite} setFavorite={setFavorite}
                              sexualOrien={sexualOrien} setSexualOrien={setSexualOrien}
                              freeTime={freeTime} setFreeTime={setFreeTime}
                              politics={politics} setPolitics={setPolitics}
                              smoking={smoking} setSmoking={setSmoking}
                              favoriteFood={favoriteFood} setFavoriteFood={setFavoriteFood}
                              favoriteSport={favoriteSport} setFavoriteSport={setFavoriteSport}
                              community={community} setCommunity={setCommunity}
                            />
                            {mode !== 'EDIT' ? (
                              <FormAction
                                sectionIndex={sectionIndex}
                                isBackLoading={isBackLoading}
                                isNextLoading={isNextLoading}
                                isButtonDisabled={isButtonDisabled}
                                handleBackClick={handleBackClick}
                                handleNextClick={handleNextClick}
                              />
                            ) : (
                              <center>
                                <div className="col-sm-12 col-md-12 col-lg-6">
                                  <div className="text-center mt-5">
                                    <button type="submit" className="btn btn-danger" style={{ width: '50%' }}>SAVE</button>
                                  </div>
                                </div>
                              </center>
                            )}
                          </form>

                          {/* Section 4 */}
                          <form className="row g-3 needs-validation" noValidate={true} hidden={sections[4]}
                            onSubmit={async (e) => {
                              e.preventDefault();
                              if (mode === 'EDIT') {
                                await handleNextClick(insertDateInfo);
                              }
                            }}>
                            <FormSection4
                              insertDateInfo={insertDateInfo}
                              lookingFor={lookingFor} setLookingFor={setLookingFor}
                              genderFor={genderFor} setGenderFor={setGenderFor}
                              ageStart={ageStart} setAgeStart={setAgeStart}
                              ageEnd={ageEnd} setAgeEnd={setAgeEnd}
                              usedCommu={usedCommu} setUsedCommu={setUsedCommu}
                              learningThai={learningThai} setLearningThai={setLearningThai}
                              thSpouseReason={thSpouseReason} setThSpouseReason={setThSpouseReason}
                              thWomenThink={thWomenThink} setThWomenThink={setThWomenThink}
                              spouseExpect={spouseExpect} setSpouseExpect={setSpouseExpect}
                              country={country} gender={gender}
                            />
                            {mode !== 'EDIT' ? (
                              <FormAction
                                sectionIndex={sectionIndex}
                                isBackLoading={isBackLoading}
                                isNextLoading={isNextLoading}
                                isButtonDisabled={isButtonDisabled}
                                handleBackClick={handleBackClick}
                                handleNextClick={handleNextClick}
                              />
                            ) : (
                              <center>
                                <div className="col-sm-12 col-md-12 col-lg-6">
                                  <div className="text-center mt-5">
                                    <button type="submit" className="btn btn-danger" style={{ width: '50%' }}>SAVE</button>
                                  </div>
                                </div>
                              </center>
                            )}
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <Footer isPadding={false} />
    </div>
  );
}

export default Register;