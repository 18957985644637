import React, { useState } from 'react';
import { FaEye, FaEyeSlash, FaCamera } from 'react-icons/fa';

function FormRegister({ insertUserLogin }) {
    const [imagePreview, setImagePreview] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    // Function to handle file selection
    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = async () => {
                setImagePreview(reader.result); // Update state with image data
            };
            reader.readAsDataURL(file);
        }
    };

    // Function to remove photo
    const handleRemovePhoto = async () => {
        setImagePreview(null); // Clear selected image
    };

    // Function to simulate click on input
    const handleIconClick = () => {
        document.getElementById('img-file').click();
    };

    // Function to handle input changes
    const onChangeInput = (id) => {
        if (document.getElementById(id).value === '') {
            document.getElementById(id).addEventListener('change', async function (event) {
                document.getElementById(id).value = event.target.value;
            });
        }
    };

    // Function to toggle password visibility
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    // Function to toggle confirm password visibility
    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    return (
        <>
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-6">
                    <div className="form-group mt-3">
                        <center>
                            <h2>Registration</h2>
                        </center>
                    </div>
                    <div className="form-group mt-3">
                        <label htmlFor="email" className="required-label">Email</label>
                        <input
                            className="form-control form-control-lg"
                            id="email"
                            type="email"
                            name="email"
                            required={true}
                            onChange={() => onChangeInput('email')} />
                    </div>
                    <div className="form-group mt-3">
                        <label htmlFor="username" className="required-label">Username</label>
                        <input
                            className="form-control form-control-lg"
                            id="username"
                            type="text"
                            name="username"
                            required={true}
                            onChange={() => onChangeInput('username')} />
                    </div>
                    <div className="form-group mt-3">
                        <label htmlFor="password" className="required-label">Password</label>
                        <div className="input-group">
                            <input
                                className="form-control form-control-lg"
                                id="password"
                                type={showPassword ? "text" : "password"}
                                name="password"
                                required={true}
                                onChange={() => onChangeInput('password')} />
                            <button className="btn btn-outline-secondary" type="button" onClick={togglePasswordVisibility}>
                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </button>
                        </div>
                    </div>
                    <div className="form-group mt-3">
                        <label htmlFor="c_password" className="required-label">Confirm Password</label>
                        <div className="input-group">
                            <input
                                className="form-control form-control-lg"
                                id="c_password"
                                type={showConfirmPassword ? "text" : "password"}
                                name="c_password"
                                required={true}
                                onChange={() => onChangeInput('c_password')} />
                            <button className="btn btn-outline-secondary" type="button" onClick={toggleConfirmPasswordVisibility}>
                                {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                            </button>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6">
                    <div className="form-group mt-5">
                        <center>
                            <h4>Add your photo</h4>
                            <br />
                            {/* Image input */}
                            <input
                                className="form-control form-control-lg"
                                id="img-file"
                                type="file"
                                name="img-file"
                                style={{ display: 'none' }} // Hide input
                                accept="image/*" // Accept all image types
                                onChange={handleFileChange} // When file changes
                            />
                            {/* Image preview */}
                            {imagePreview && (
                                <div className="mt-3">
                                    <div className="circular-preview" style={{ width: '200px', height: '200px', overflow: 'hidden', borderRadius: '50%' }}>
                                        <img src={imagePreview} alt="Preview" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                    </div>
                                    <br />
                                    <a onClick={handleRemovePhoto} style={{ textDecoration: 'underline', color: 'black', cursor: 'pointer' }}>Remove Photo</a>
                                </div>
                            )}
                            {/* Icon for file selection */}
                            {!imagePreview && (
                                <FaCamera
                                    style={{ fontSize: "50px", cursor: 'pointer' }}
                                    onClick={handleIconClick} // Link to icon click
                                />
                            )}
                        </center>
                    </div>
                </div>
                <center>
                    <div className="col-sm-12 col-md-12 col-lg-6">
                        <div className="text-center mt-5">
                            <button type="submit" className="btn btn-lg btn-danger" style={{ width: '100%' }}>CREATE ACCOUNT</button>
                        </div>
                    </div>
                </center>

                {/* Modal for Confirm Dialog */}
                <button id="confirm-dialog-btn" type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#confirmDialogModal" hidden />
                <div className="modal fade" id="confirmDialogModal" tabIndex="-1" aria-labelledby="confirmDialogLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="confirmDialogLabel">Initial Registration Complete</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                Would you like to provide additional details?
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => insertUserLogin("proceed")}>Yes</button>
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => insertUserLogin("later")}>No</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FormRegister;