import '../../../assets/css/profile.css';
import Header from "components/Headers/Header";
import Footer from "components/Footers/Footer";
import Section1 from 'components/Profile/Section1';
import Section2 from 'components/Profile/Section2';
import Section3 from 'components/Profile/Section3';
import Section4 from 'components/Profile/Section4';
import React from 'react';

function EditProfile() {
    const userId = localStorage.getItem('user_id');

    return (
        <div className="EditProfile">
            <Header />
            <div style={{ padding: "1rem", backgroundColor: "#fae8de" }}>
                <div className="container rounded-container">
                    {/* Section 1 */}
                    <Section1 userId={userId} isOwnerProfile={true} />

                    <hr />

                    {/* Section 2 */}
                    <Section2 userId={userId} isOwnerProfile={true} />

                    <hr />

                    {/* Section 3 */}
                    <Section3 userId={userId} isOwnerProfile={true} />

                    <hr />

                    {/* Section 4 */}
                    <Section4 userId={userId} isOwnerProfile={true} />

                </div>
            </div>
            <Footer isPadding={false} />
        </div>
    );
}

export default EditProfile;
