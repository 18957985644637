import '../../../assets/css/404.css';
import Header from "components/Headers/Header";
import Footer from "components/Footers/Footer";

const NotFoundPage = () =>
  <div>
    <Header />
    <div id="notfound">
      <div className="notfound">
        <div className="notfound-404">
          <h1>4<span></span>4</h1>
        </div>
        <h2>Oops! Page Not Be Found</h2>
      </div>
    </div>
    <Footer isPadding={false}  />
  </div>

export default NotFoundPage;