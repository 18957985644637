import PreviewsToggle from '../../components/Toggle/PreviewsToggle';
import React, { useEffect, useState } from 'react';

// Utils
import { getDataByUserId, createAlert, handleEditSection } from 'utils/MainUtils';

function Section3({ userId, isOwnerProfile }) {
    const [freeTime, setFreeTime] = useState('');
    const [hideProfile, setHideProfile] = useState(false);
    const [isOwnerHidden, setIsOwnerHidden] = useState(false);

    useEffect(() => {
        const initialize = async () => {
            try {
                const favoriteInfo = await getDataByUserId("FAVORITE_INFO", userId, isOwnerProfile ? "" : "Y");
                if (favoriteInfo && favoriteInfo.length > 0) {
                    setFreeTime(favoriteInfo[0].free_time);
                } else {
                    setHideProfile(true);
                }

                // Check Hidden For OwnerProfile
                if (isOwnerProfile) {
                    const ownerProfile = await getDataByUserId("USER_INFO", userId, "Y");
                    if (ownerProfile && ownerProfile.length > 0) {
                        setIsOwnerHidden(false);
                    } else {
                        setIsOwnerHidden(true);
                    }
                }
            } catch (error) {
                createAlert("FAILED", error);
            }
        };

        initialize();
    }, [userId]);

    return (
        <>
            <div id="section-3" style={{ padding: "1rem" }}>
                <div className="row align-items-center">
                    <div className="col-12 col-lg-6">
                        <h5 style={{ color: 'red' }}>
                            Favorite {isOwnerHidden && <span style={{ color: 'black' }}> [Hidden]</span>}
                        </h5>
                    </div>
                    <div className="col-12 col-lg-6 text-lg-end" hidden={!isOwnerProfile}>
                        <button onClick={() => handleEditSection("3")}
                            className="btn btn-danger w-20 w-lg-auto">Edit</button>
                    </div>
                </div>

                {/* row 1 */}
                <div className="row"
                    style={{ padding: "1rem" }}>
                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <h6 id="h6-header">How often do you drink?</h6>
                        <PreviewsToggle id={userId} button={true} rf={"DRINKING"} hide={hideProfile} />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <h6 id="h6-header">Do you Smoking?</h6>
                        <PreviewsToggle id={userId} button={true} rf={"SMOKING"} hide={hideProfile} />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <h6 id="h6-header">What do you like to do in your free time?</h6>
                        <p id="p-detail">{hideProfile ? '[Hidden]' : freeTime}</p>
                    </div>
                </div>

                {/* row 2 */}
                <div className="row"
                    style={{ padding: "1rem" }}>
                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <h6 id="h6-header">What do you like?</h6>
                        <PreviewsToggle id={userId} button={true} rf={"FAVORITE"} hide={hideProfile} />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <h6 id="h6-header">Favorite food</h6>
                        <PreviewsToggle id={userId} button={true} rf={"FOOD"} hide={hideProfile} />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <h6 id="h6-header">Favorite sport</h6>
                        <PreviewsToggle id={userId} button={true} rf={"SPORT"} hide={hideProfile} />
                    </div>
                </div>

                {/* row 3 */}
                <div className="row"
                    style={{ padding: "1rem" }}>
                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <h6 id="h6-header">What is your sexual orientation?</h6>
                        <PreviewsToggle id={userId} button={true} rf={"SEXUAL_ORIEN"} hide={hideProfile} />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <h6 id="h6-header">Causes and communities</h6>
                        <PreviewsToggle id={userId} button={true} rf={"COMMUNITY"} hide={hideProfile} />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <h6 id="h6-header">Politics</h6>
                        <PreviewsToggle id={userId} button={true} rf={"POLITICS"} hide={hideProfile} />
                    </div>
                </div>

            </div>
        </>
    );
}

export default Section3;