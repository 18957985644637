import '../../assets/css/components/comp_login.css';
import React from 'react';

const CompLogin = ({ isLogin, userId, userImg, userName, logout }) => {
  return (
    <>
      {isLogin ? (
        <ul className="navbar-nav ms-auto mb-2 mb-lg-0 profile-menu">
          <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle d-flex align-items-center" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <div className="profile-pic">
                <img src={userImg} alt="Profile Picture" className="rounded-circle" />
              </div>
              <div className="ms-2">
                <h5 style={{ margin: 0, color: "red" }}>{userName}</h5>
              </div>
            </a>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><a className="dropdown-item" href={`/my-profile`}><i className="fa fa-user fa-fw"></i>My Profile</a></li>
              <li><hr className="dropdown-divider" /></li>
              <li><a className="dropdown-item" href={`/my-interest`}><i className="fa fa-star fa-fw"></i>My Interest</a></li>
              <li><hr className="dropdown-divider" /></li>
              <li><a className="dropdown-item" href={`/interest-me`}><i className="fa fa-star-o fa-fw"></i>Interest me</a></li>
              <li><hr className="dropdown-divider" /></li>
              <li><a className="dropdown-item" href={`/matching`}><i className="fa fa-heart fa-fw"></i>Matching</a></li>
              <li><hr className="dropdown-divider" /></li>
              <li><a className="dropdown-item" href={`/my-package`}><i className="fa fa-shopping-bag fa-fw"></i>My Package</a></li>
              <li><hr className="dropdown-divider" /></li>
              <li><a className="dropdown-item" onClick={() => logout()}><i className="fa fa-sign-out fa-fw"></i>Log Out</a></li>
            </ul>
          </li>
        </ul>
      ) : (
        <a href="/login" type="button" className="btn btn-danger" style={{ width: '100%' }}>Login</a>
      )}
    </>
  );
}

export default CompLogin;
