import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';

// Style
// import "bootstrap/scss/bootstrap.scss";

// Pages
import Home from "views/pages/Home/Home";
import About from "views/pages/About/About";
import Contact from "views/pages/Contact/Contact";
import Update from "views/pages/Update/Update";
import UpdateForm from "views/pages/Update/UpdateForm";
import Search from "views/pages/Search/Search";
import Interest from "views/pages/Search/Interest";
import Matching from "views/pages/Search/Matching";
import Profile from "views/pages/Profile/Profile";
import EditProfile from "views/pages/Profile/EditProfile";
import Login from "views/pages/Auth/Login";
import Register from "views/pages/Auth/Register";
import ResetPassword from "views/pages/Auth/ResetPassword";
import Package from "views/pages/Package/Package";
import NotFoundPage from "views/pages/Error/NotFoundPage";

function ScrollToTopOnPageChange() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const ProfileWrapper = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const isLoggedIn = localStorage.getItem('token');

  React.useEffect(() => {
    if (!id) {
      navigate("/search");
    }
  }, [id, navigate]);

  return isLoggedIn ? <Profile /> : <Navigate to="/" />;
};

const root = ReactDOM.createRoot(document.getElementById("root"));
const isLoggedIn = localStorage.getItem('token');

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/home" element={<Home />} />
      <Route path="/about-us" element={<About />} />
      <Route path="/contact-us" element={<Contact />} />
      <Route path="/update" element={<Update />} />
      <Route path="/update-form" element={<UpdateForm />} />
      <Route path="/search" element={isLoggedIn ? <Search /> : <Navigate to="/" />} />
      <Route path="/profile" element={<ProfileWrapper />} />
      <Route path="/login" element={isLoggedIn ? <Navigate to="/" /> : <Login />} />
      <Route path="/register" element={isLoggedIn ? <Register mode="UPDATE" /> : <Register mode="INSERT" />} />
      <Route path="/my-profile" element={isLoggedIn ? <EditProfile /> : <Navigate to="/" />} />
      <Route path="/my-interest" element={isLoggedIn ? <Interest choice={'TO'} /> : <Navigate to="/" />} />
      <Route path="/interest-me" element={isLoggedIn ? <Interest choice={'ME'} /> : <Navigate to="/" />} />
      <Route path="/matching" element={isLoggedIn ? <Matching /> : <Navigate to="/" />} />
      <Route path="/my-package" element={isLoggedIn ? <Package /> : <Navigate to="/" />} />
      <Route path="/edit-profile" element={isLoggedIn ? <Register mode="EDIT" /> : <Navigate to="/" />} />
      <Route path="/re-password" element={<ResetPassword />} />
      <Route path="*" element={<NotFoundPage />} />
      <Route element={<ScrollToTopOnPageChange />} />
    </Routes>
  </BrowserRouter>
);
