import React, { useState, useEffect } from 'react';
import Header from 'components/Headers/Header';
import Footer from 'components/Footers/Footer';

import updatesData from 'update.json';
// import updatesData from 'update-prd.json';

import { createAlert } from 'utils/MainUtils'; // นำเข้าฟังก์ชัน createAlert จาก utils/MainUtils

function UpdateForm() {
  const [version, setVersion] = useState('');
  const [detail, setDetail] = useState('');
  const [updates, setUpdates] = useState(updatesData);

  useEffect(() => {
    // Calculate the next version based on the latest version
    const latestVersion = updates[updates.length - 1].version;
    const [major, minor, patch] = latestVersion.split('.').map(Number);
    const nextVersion = `${major}.${minor}.${patch + 1}`;
    setVersion(nextVersion);
  }, [updates]);

  const handleSubmit = (event) => {
    event.preventDefault();

    // Generate updated_on in the format "dd/mm/yyyy hh:mm:ss"
    const now = new Date();
    const updatedOn = `${now.getDate().toString().padStart(2, '0')}/${(now.getMonth() + 1).toString().padStart(2, '0')}/${now.getFullYear() + 543} ${now.getHours().toString().padStart(2, '0')}:${now.getMinutes().toString().padStart(2, '0')}:${now.getSeconds().toString().padStart(2, '0')}`;

    // Construct new update object
    const newUpdate = {
      version: version,
      detail: detail.split('\n').map(item => item.trim()),
      updated_on: updatedOn
    };

    // Add new update to updates array
    const newUpdates = [...updates, newUpdate];
    setUpdates(newUpdates);

    // Clear form inputs
    setVersion('');
    setDetail('');

    // Show success message
    createAlert("OK", "Create JSON Successfully");
  };

  const handleCopy = () => {
    const updatesText = JSON.stringify(updates, null, 2);
    navigator.clipboard.writeText(updatesText)
      .then(() => {
        createAlert("OK", "Updates copied to clipboard successfully");
      })
      .catch(() => {
        createAlert("FAILED", "Failed to copy updates to clipboard");
      });
  };

  return (
    <div className="UpdateForm">
      <Header />
      <div className="container" style={{ padding: "2rem" }}>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="versionInput" className="form-label">Version</label>
            <input
              type="text"
              className="form-control"
              id="versionInput"
              value={version}
              onChange={(e) => setVersion(e.target.value)}
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="detailTextarea" className="form-label">Detail</label>
            <textarea
              className="form-control"
              id="detailTextarea"
              rows="10"
              value={detail}
              onChange={(e) => setDetail(e.target.value)}
              required
            ></textarea>
          </div>
          <button type="submit" className="btn btn-primary">บันทึก</button>
        </form>

        {/* Display updates */}
        <div className="mt-5">
          <h2>รายการ Updates</h2>
          <pre>{JSON.stringify(updates, null, 2)}</pre>
          <button onClick={handleCopy} className="btn btn-secondary mt-2">คัดลอก</button>
        </div>
      </div>
      <Footer isPadding={false} />
    </div>
  );
}

export default UpdateForm;