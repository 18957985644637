import '../../assets/css/components/comp_header.css';
import React, { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import Axios from 'axios';
import CompLogin from "../Login/CompLogin";

function Header() {
  const [userId, setUserId] = useState('');
  const [userImg, setUserImg] = useState('');
  const [username, setUsername] = useState('');
  const [isLogin, setIsLogin] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const location = useLocation();

  useEffect(() => {
    const fetchMenuItems = async () => {
      try {
        const response = await Axios.get(`${process.env.REACT_APP_API_URL}/api/menu`, {
          headers: {
            key: process.env.REACT_APP_ANALYTICS_KEY,
          },
        });
        setMenuItems(response.data);
      } catch (err) {
        console.log(err.message);
      }
    };

    fetchMenuItems();
  }, []);

  useEffect(() => {
    const fetchLoginUser = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) return; // Return early if no token

        const response = await Axios.post(`${process.env.REACT_APP_API_URL}/api/authen`, { name: '' }, {
          headers: { 'Authorization': 'Bearer ' + token }
        });

        if (response.data.status === 'OK') {
          const res = await Axios.get(`${process.env.REACT_APP_API_URL}/api/get-user-by-username/${response.data.decoded.username}`, {
            headers: { key: process.env.REACT_APP_ANALYTICS_KEY }
          });
          if (res.data.status === 'FAILED') {
            window.location.replace("/login");
          } else {
            const userImgResponse = await Axios.post(`${process.env.REACT_APP_API_URL}/api/get-user-img`, {
              user_id: res.data.user[0].user_id,
              img_seq: "1"
            }, {
              headers: {
                key: process.env.REACT_APP_ANALYTICS_KEY,
              }
            });
            setUserImg(userImgResponse.data.image);
            setUserId(res.data.user[0].user_id);
            setUsername(res.data.user[0].username);
            setIsLogin(true);
          }
        } else {
          setIsLogin(false);
          localStorage.removeItem('token');
          localStorage.removeItem('user_id');
        }
      } catch (err) {
        console.log(err.message);
        setIsLogin(false);
        localStorage.removeItem('token');
        localStorage.removeItem('user_id');
      }
    };

    fetchLoginUser();
  }, []);

  const logout = useCallback(() => {
    localStorage.removeItem('token');
    localStorage.removeItem('user_id');
    setIsLogin(false);
    window.location.reload();
  }, []);

  return (
    <div className="Header">
      {/* <div className="preloader-wrapper"><div className="preloader"></div></div> */}
      <nav className="main-menu d-flex navbar navbar-expand-lg p-2 py-3 p-lg-4 py-lg-4">
        <div className="container-fluid">
          <div className="main-logo d-lg-none">
            <a href="/">
              <img src="images/new-logo.png" alt="logo" className="img-fluid" width={75} />
            </a>
          </div>
          <button className="navbar-toggler shadow-none" type="button" data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
            <span className="navbar-toggler-icon" />
          </button>
          <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel">
            <div className="offcanvas-header mt-3">
              <button type="button" className="btn-close shadow-none" data-bs-dismiss="offcanvas"
                aria-label="Close" />
            </div>
            <div className="offcanvas-body justify-content-between">
              <div className="main-logo">
                <a href="/">
                  <center>
                    <img src="images/new-logo.png" alt="logo" className="img-fluid" width={100} style={{ marginLeft: '50px' }} />
                  </center>
                </a>
                <div className="d-lg-none mt-3">
                  <center>
                    <CompLogin isLogin={isLogin} userId={userId} userImg={userImg} userName={username} logout={logout} />
                  </center>
                </div>
              </div>

              <ul className="navbar-nav menu-list list-unstyled align-items-lg-center d-flex gap-md-3 mb-0 mx-auto">
                {menuItems.map((item) => (
                  (item.menu_path !== '/search' || isLogin) && (
                    <li key={item.menu_id} className="nav-item">
                      <a
                        href={item.menu_path}
                        className={`nav-link mx-2 ${location.pathname === item.menu_path ? 'active' : ''}`}
                      >
                        {item.menu_name}
                      </a>
                    </li>
                  )
                ))}
              </ul>

              <div className="d-none d-lg-flex align-items-center" style={{ marginRight: '100px' }}>
                <CompLogin isLogin={isLogin} userId={userId} userImg={userImg} userName={username} logout={logout} />
              </div>
            </div>
          </div>
        </div>
      </nav>
      <div id="success-container"></div>
      <div id="error-container"></div>
    </div>
  );
}

export default Header;