import PreviewsToggle from '../../components/Toggle/PreviewsToggle';
import React, { useEffect, useState } from 'react';
import Axios from 'axios';

// Utils
import { getDataByUserId, createAlert, handleEditSection } from 'utils/MainUtils';

function Section2({ userId, isOwnerProfile }) {
    const [weight, setWeight] = useState('');
    const [height, setHeight] = useState('');
    const [numChild, setNumChild] = useState('');
    
    const [hideProfile, setHideProfile] = useState(false);
    const [isOwnerHidden, setIsOwnerHidden] = useState(false);

    useEffect(() => {
        const initialize = async () => {
            try {
                // PERSON_INFO
                const personInfo = await getDataByUserId("PERSON_INFO", userId, isOwnerProfile ? "" : "Y");
                if (personInfo && personInfo.length > 0) {
                    setWeight(personInfo[0].weight);
                    setHeight(personInfo[0].height);
                    setNumChild(personInfo[0].num_child);
                } else {
                    setHideProfile(true);
                }

                // Check Hidden For OwnerProfile
                if (isOwnerProfile) {
                    const ownerProfile = await getDataByUserId("USER_INFO", userId, "Y");
                    if (ownerProfile && ownerProfile.length > 0) {
                        setIsOwnerHidden(false);
                    } else {
                        setIsOwnerHidden(true);
                    }
                }
            } catch (error) {
                createAlert("FAILED", error);
            }
        };

        initialize();
    }, [userId]);

    return (
        <>
            <div id="section-2" style={{ padding: "1rem" }}>
                <div className="row align-items-center">
                    <div className="col-12 col-lg-6">
                        <h5 style={{ color: 'red' }}>
                            Personal Information
                            {isOwnerHidden && <span style={{ color: 'black' }}> [Hidden]</span>}
                        </h5>
                    </div>
                    <div className="col-12 col-lg-6 text-lg-end" hidden={!isOwnerProfile}>
                        <button onClick={() => handleEditSection("2")}
                            className="btn btn-danger w-20 w-lg-auto">Edit</button>
                    </div>
                </div>

                {/* row 1 */}
                <div className="row"
                    style={{ padding: "1rem" }}>
                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <b id="h6-header">Height (cm.)</b>
                        <p id="p-detail">{hideProfile ? '[Hidden]' : height}</p>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <b id="h6-header">Weight (kg.)</b>
                        <p id="p-detail">{hideProfile ? '[Hidden]' : weight}</p>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <b id="h6-header">Shape</b>
                        <PreviewsToggle id={userId} button={true} rf={"SHAPE"} hide={hideProfile} />
                    </div>
                </div>

                {/* row 2 */}
                <div className="row"
                    style={{ padding: "1rem" }}>
                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <b id="h6-header">Marital Status</b>
                        <PreviewsToggle id={userId} button={true} rf={"MARITAL_STAT"} hide={hideProfile} />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <b id="h6-header">Do you want children?</b>
                        <PreviewsToggle id={userId} button={true} rf={"KIDS"} hide={hideProfile} />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <b id="h6-header">Number of children?</b>
                        <p id="p-detail">{hideProfile ? '[Hidden]' : numChild}{isOwnerHidden ? '[Hidden]' : ''}</p>
                    </div>
                </div>

                {/* row 3 */}
                <div className="row"
                    style={{ padding: "1rem" }}>
                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <b id="h6-header">Who do you live with?</b>
                        <PreviewsToggle id={userId} button={true} rf={"LIVE_WITH"} hide={hideProfile} />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <b id="h6-header">Who influences you the most?</b>
                        <PreviewsToggle id={userId} button={true} rf={"INFLUENCES_FACTOR"} hide={hideProfile} />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <b id="h6-header">Ethnicity</b>
                        <PreviewsToggle id={userId} button={true} rf={"ETHNICITY"} hide={hideProfile} />
                    </div>
                </div>

                {/* row 4 */}
                <div className="row"
                    style={{ padding: "1rem" }}>
                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <b id="h6-header">Religion</b>
                        <PreviewsToggle id={userId} button={true} rf={"RELIGION"} hide={hideProfile} />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <b id="h6-header">What is your zodiac sign?</b>
                        <PreviewsToggle id={userId} button={true} rf={"ZODIAC"} hide={hideProfile} />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <b id="h6-header">Education</b>
                        <PreviewsToggle id={userId} button={true} rf={"EDUCATION"} hide={hideProfile} />
                    </div>
                </div>

                {/* row 5 */}
                <div className="row"
                    style={{ padding: "1rem" }}>
                    <div className="col-4">
                        <b id="h6-header">Religion</b>
                        <PreviewsToggle id={userId} button={true} rf={"PERSON_VALUE"} hide={hideProfile} />
                    </div>
                </div>

            </div>
        </>
    );
}

export default Section2;