import '../../../assets/css/re-password.css';

import React, { useEffect, useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import Axios from "axios";
import Header from "components/Headers/Header";
import Footer from "components/Footers/Footer";

// Utils
import { createAlert } from 'utils/MainUtils';

function ResetPassword() {
  const location = useLocation();
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [tokenValid, setTokenValid] = useState(true);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');

    if (!token) {
      setTokenValid(false);
    } else {
      Axios.post(`${process.env.REACT_APP_API_URL}/api/reset-password/authen`, {}, {
        headers: { 'Authorization': 'Bearer ' + token }
      })
        .then(response => {
          if (response.data.status === 'OK') {
            setTokenValid(true);
            setUsername(response.data.decoded.username)
            setEmail(response.data.decoded.email);
          } else {
            setTokenValid(false);
            setUsername('')
            setEmail('');
          }
        })
        .catch(error => {
          console.error('Error validating token:', error);
          setTokenValid(false);
        });
    }
  }, [location.search]);

  const handleResetPassword = async () => {
    if (newPassword !== confirmPassword) {
      createAlert("FAILED", "Passwords do not match");
      return;
    }

    const confirmed = window.confirm("Are you sure you want to reset your password?");
    if (!confirmed) {
      return;
    }

    try {
      const response = await Axios.post(`${process.env.REACT_APP_API_URL}/api/edit-password`,
        {
          username: username,
          email: email,
          password: newPassword
        },
        {
          headers: {
            key: process.env.REACT_APP_ANALYTICS_KEY,
          }
        }
      );

      if (response.data.status === 'OK') {
        setPasswordResetSuccess(true);
      } else {
        createAlert(response.data.status, response.data.message);
      }
    } catch (error) {
      console.error('Error updating password:', error);
      createAlert("FAILED", error.message);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  if (!tokenValid) {
    return (
      <>
        <Header />
        <div className="text-center" style={{ padding: '5rem' }}>
          <b style={{ fontSize: '20px' }}>The token provided is either invalid or has expired.</b>
        </div>
        <Footer isPadding={false} />
      </>
    );
  }

  if (passwordResetSuccess) {
    return (
      <>
        <Header />
        <section className="change-pass-success section" style={{ padding: '5rem' }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-3 col-12">
                <div className="success-inner">
                  <h1><i className="fa fa-check-circle-o"></i><span>Password Change Successful!</span></h1>
                  <p>Your password has been successfully changed. You may now proceed to the login page and log in with your new password.</p>
                  <a href="/login" className="btn btn-danger btn-lg">Go to Login</a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer isPadding={false} />
      </>
    );
  }

  return (
    <div className="Register" style={{ height: "100vh" }}>
      <Header />
      <div className="content" style={{ height: "calc(100vh - 100px)", backgroundColor: "#fae8de" }}>
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
              <div className="d-table-cell align-middle">
                <div className="card">
                  <div className="card-body">
                    <div className="m-sm-4">
                      <h2 style={{ textAlign: 'center' }}>Reset your password</h2>
                      <div className="form-group mt-3">
                        <label htmlFor="password" className="required-label">New Password</label>
                        <div className="input-group">
                          <input
                            className="form-control form-control-lg"
                            id="password"
                            type={showPassword ? "text" : "password"}
                            name="password"
                            required={true}
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)} />
                          <button className="btn btn-outline-secondary" type="button" onClick={togglePasswordVisibility}>
                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                          </button>
                        </div>
                      </div>
                      <div className="form-group mt-3">
                        <label htmlFor="c_password" className="required-label">Confirm Password</label>
                        <div className="input-group">
                          <input
                            className="form-control form-control-lg"
                            id="c_password"
                            type={showConfirmPassword ? "text" : "password"}
                            name="c_password"
                            required={true}
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)} />
                          <button className="btn btn-outline-secondary" type="button" onClick={toggleConfirmPasswordVisibility}>
                            {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="text-center">
                          <button className="btn btn-lg btn-danger" onClick={handleResetPassword}>Reset Password</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer isPadding={false} />
    </div>
  );
}

export default ResetPassword;