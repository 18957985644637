import { useState } from 'react';

const useAbstractModelForm = () => {
    const [userId, setUserId] = useState('');
    const [activeFlag, setActiveFlag] = useState('');
    const [createUser, setCreateUser] = useState('');
    const [createDatetime, setCreateDatetime] = useState('');
    const [createProcess, setCreateProcess] = useState('');
    const [updateUser, setUpdateUser] = useState('');
    const [updateDatetime, setUpdateDatetime] = useState('');
    const [updateProcess, setUpdateProcess] = useState('');

    return {
        userId,
        setUserId,

        activeFlag,
        setActiveFlag,

        createUser,
        setCreateUser,

        createDatetime,
        setCreateDatetime,

        createProcess,
        setCreateProcess,

        updateUser,
        setUpdateUser,

        updateDatetime,
        setUpdateDatetime,

        updateProcess,
        setUpdateProcess
    };
};

export default useAbstractModelForm;