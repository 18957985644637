import { Link } from 'react-router-dom';

function UserCard({ userList, message, style }) {
    return (
        <div style={{ padding: "2rem", backgroundColor: "#fae8de" }}>
            <div className="container" style={style}>
                <h1 style={{ color: 'red' }}>{message}</h1>
                {userList.length === 0 ? (
                    <div className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
                        <h2>No results found</h2>
                    </div>
                ) : (
                    <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-4 g-4 mt-5">
                        {userList.map(user => (
                            <div key={user.id} className="col mb-5">
                                <div className="card rounded-4 border-0 shadow-sm p-3 position-relative">
                                    <Link to={`/profile?id=${user.id}`}>
                                        <center>
                                            <img src={user.img} className="img-fluid rounded-3 fixed-image-size" alt="Avatar" />
                                        </center>
                                    </Link>
                                    <div className="mt-3 card-body p-0">
                                        <Link to={`/profile?id=${user.id}`}>
                                            <h5 className="course-title py-2 m-0" style={{ color: 'red' }}>{user.name}, {user.age}</h5>
                                        </Link>
                                        <div className="card-text">
                                            <span className="rating d-flex align-items-center">
                                                <p className="text-muted fw-semibold m-0 me-2 mt-2">{user.caption === null ? 'No caption' : user.caption}</p>
                                                <div hidden={user.caption === 'No caption'}>
                                                    <br /> <br />
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}

export default UserCard;