import PreviewsToggle from '../../components/Toggle/PreviewsToggle';
import React, { useEffect, useState } from 'react';

// Utils
import { getDataByUserId, createAlert, handleEditSection } from 'utils/MainUtils';

function Section4({ userId, isOwnerProfile }) {
    const [ageStart, setAgeStart] = useState('');
    const [ageEnd, setAgeEnd] = useState('');
    const [thSpouseReason, setThSpouseReason] = useState('');
    const [thWomenThink, setThWomenThink] = useState('');
    const [spouseExpect, setSpouseExpect] = useState('');
    const [hideProfile, setHideProfile] = useState(false);
    const [isOwnerHidden, setIsOwnerHidden] = useState(false);

    useEffect(() => {
        const initialize = async () => {
            try {
                const dateInfo = await getDataByUserId("DATE_INFO", userId, isOwnerProfile ? "" : "Y");
                if (dateInfo && dateInfo.length > 0) {
                    setAgeStart(dateInfo[0].age_start);
                    setAgeEnd(dateInfo[0].age_end);
                    setThSpouseReason(dateInfo[0].th_spouse_reason);
                    setThWomenThink(dateInfo[0].th_women_think);
                    setSpouseExpect(dateInfo[0].spouse_expect);
                } else {
                    setHideProfile(true);
                }

                // Check Hidden For OwnerProfile
                if (isOwnerProfile) {
                    const ownerProfile = await getDataByUserId("USER_INFO", userId, "Y");
                    if (ownerProfile && ownerProfile.length > 0) {
                        setIsOwnerHidden(false);
                    } else {
                        setIsOwnerHidden(true);
                    }
                }
            } catch (error) {
                createAlert("FAILED", error);
            }
        };

        initialize();
    }, [userId]);

    return (
        <>
            <div id="section-4" style={{ padding: "1rem" }}>
                <div className="row align-items-center">
                    <div className="col-12 col-lg-6">
                        <h5 style={{ color: 'red' }}>
                            Date Information {isOwnerHidden && <span style={{ color: 'black' }}> [Hidden]</span>}
                        </h5>
                    </div>
                    <div className="col-12 col-lg-6 text-lg-end" hidden={!isOwnerProfile}>
                        <button onClick={() => handleEditSection("4")}
                            className="btn btn-danger w-20 w-lg-auto">Edit</button>
                    </div>
                </div>

                {/* row 1 */}
                <div className="row"
                    style={{ padding: "1rem" }}>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <b id="h6-header">What are you looking for?</b>
                        <PreviewsToggle id={userId} button={true} rf={"LOOKING_FOR"} hide={hideProfile} />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <b id="h6-header">Gender looking for?</b>
                        <PreviewsToggle id={userId} button={true} rf={"GENDER_FOR"} hide={hideProfile} />
                    </div>
                </div>

                {/* row 2 */}
                <div className="row"
                    style={{ padding: "1rem" }}>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <b id="h6-header">Age range of interest</b>
                        <p id="p-detail">{hideProfile ? '[Hidden]' : `${ageStart} - ${ageEnd} years old`}</p>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <b id="h6-header">Why do you want to have a Thai spouse?</b>
                        <p id="p-detail">{hideProfile ? '[Hidden]' : thSpouseReason}</p>
                    </div>
                </div>

                {/* row 3 */}
                <div className="row"
                    style={{ padding: "1rem" }}>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <b id="h6-header">What do you think about Thai woman/man?</b>
                        <p id="p-detail">{hideProfile ? '[Hidden]' : thWomenThink}</p>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <b id="h6-header">What do you expect from your future spouse when you marry him/her?</b>
                        <p id="p-detail">{hideProfile ? '[Hidden]' : spouseExpect}</p>
                    </div>
                </div>

                {/* row 4 */}
                <div className="row"
                    style={{ padding: "1rem" }}>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <b id="h6-header">Do you think the language used in communication is important?</b>
                        <PreviewsToggle id={userId} button={true} rf={"LANG_IMPORTANT"} hide={hideProfile} />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <b id="h6-header">Are you interested in learning Thai?</b>
                        <PreviewsToggle id={userId} button={true} rf={"LEARN_TH"} hide={hideProfile} />
                    </div>
                </div>

            </div>
        </>
    );
}

export default Section4;